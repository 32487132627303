/* Effect 15:  3D Rotate in from left */
.@{modal-prefix}-effect-15.@{modal-prefix}-container {
	-webkit-perspective: 1300px;
	-moz-perspective: 1300px;
	perspective: 1300px;
}

.@{modal-prefix}-effect-15 .@{modal-prefix}-content {
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
	-moz-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
	-ms-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
	transform: translateZ(100px) translateX(-30%) rotateY(90deg);
	-webkit-transform-origin: 0 100%;
	-moz-transform-origin: 0 100%;
	transform-origin: 0 100%;
	opacity: 0;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.@{modal-prefix}-show.@{modal-prefix}-effect-15 .@{modal-prefix}-content {
	-webkit-transform: translateZ(0px) translateX(0%) rotateY(0deg);
	-moz-transform: translateZ(0px) translateX(0%) rotateY(0deg);
	-ms-transform: translateZ(0px) translateX(0%) rotateY(0deg);
	transform: translateZ(0px) translateX(0%) rotateY(0deg);
	opacity: 1;
}