/*------------------------------------------------------------------
  [Page aside]
*/
.be-aside{

	.main-content{
		margin-left: @page-aside-width;

		@media @phone{
			margin-left: inherit;
		}
	}
}

.page-aside{
  background: none repeat scroll 0 0 @light-color;
  width: @page-aside-width;
  height: 100%;
  position: fixed;
  top: 0;
  left: @left-sidebar-width;
  border-right: 1px solid darken(@light-color, 10%);
  margin-top: @top-header-height + 1px;
	padding-bottom: @top-header-height + 1px;

  .be-scroller{
    position: relative;
    height: 100%;
    width: 100%;
  }

  .content{
  	padding: 20px @page-aside-padding;
    position: relative;
  }

	@media @phone{
    width: 100%;
    display: block;
    height: auto;
    position: relative;
    margin-top: 0;
    padding-bottom: 0;
    left: 0;
    border-bottom: 2px solid darken(@light-color, 5%);
	}
}

.aside-header{
  margin-bottom: 5px;

  .title{
    display: block;
    margin: 16px 0 0;
    font-size: @page-aside-title-size;
    line-height: @page-aside-title-size;
    font-weight: @font-weight-light;
  }

  .navbar-toggle{
    padding: 0;
    padding-right: 11px;
    text-align: right;
    margin: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;

    .icon{
      font-size: 27px;
      color: lighten(@text-color, 50%)
    }
  }

  .description{
    color: lighten(@text-color, 10%);
    margin: 0;
  }
}

.aside-nav{
  display: block;
  visibility: visible;

  .nav{

    li{

      a{
        color: @text-color;
        padding: 13px @page-aside-padding;
      }

      &.active{
        
        a{
          color: @primary-color;

          .icon{
            color: @primary-color;
          }
        }
      }

      .label{
        float: right;
        font-size: 12px;
        font-weight: @font-weight-light;
        padding: 4px 7px;
      }

      .icon{
        font-size: 19px;
        vertical-align: middle;
        text-align: center;
        min-width: 19px;
        margin-right: 3px;
        color: lighten(@text-color, 13%);
      }
    } 
  }

  .title{
    display: block;
    color: lighten(@text-color, 35%);
    font-size: @font-size-small - 1px;
    font-weight: @font-weight-bold;
    text-transform: uppercase;
    margin: 20px 0 0;
    padding: 8px @page-aside-padding 4px;
  }

  @media @phone{
    border-bottom: 1px solid darken(@light-color, 10%);

    &.collapse{
      display: none;
    }

    &.in{
      display: block;
    }
  }
}