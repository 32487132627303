.be-notifications{
	padding-top: 0;

	.navbar-right &{
		left: auto;
		right: @content-padding;
	}

	.be-scroller{
		position: relative;
		height: 222px;

		> .content{

			> ul{
				list-style: none;
				padding-left: 0;
			}
		}
	}

	.notification{
		overflow: hidden;
		border-bottom: 1px solid darken(@light-color, 7.7%);

		> a{
			padding: 15px;
			display: block;
			overflow: hidden;

			.image{
				height: 38px;
				width: 38px;
				border-radius: 50%;
				background-color: darken(@light-color, 10.5%);
				float: left;												
				text-align: center;
				overflow: hidden;

				img{
					height: 38px;
					width: 38px;
					font-size: 25px;
					color: lighten(@dark-color, 35%);
					line-height: 36px;
				}
			}

			.notification-info{
				padding-left: 50px;
				line-height: 14px;

				.text{
					font-weight: 500;
					font-size: 13px;
					color: darken(@light-color, 45.7%);
					font-size: 12px;
					line-height: 16px;
				}

				.user-name{
					color: @link-color;
					font-size: 13px;
				}

				.date{
					color: darken(@light-color, 45.7%);
					display: block;
					font-size: 9px;
					margin-top: 4px;
					text-transform: uppercase;
				}
			}
		}

		&.notification-unread{
			background-color: fade(@primary-color, 10%);
			border-bottom: 1px solid fade(@primary-color, 10%);

			> a{
				position: relative;

				&:after{
					content: '';
					display: block;
					position: absolute;
					right: 15px;
					top: 20px;
					width: 6px;
					height: 6px;
					background-color: @primary-color;
					border-radius: 50%;
				}
			}
		}

		&:hover{
			background-color: @primary-color;

			> a{
				color: @light-color;

				.logo{
					background-color: darken(@primary-color, 7%);

					.icon{
						color: @light-color;
					}
				}

				.notification-info{

					.circle{
						background: @light-color;
					}

					.text, .date, .user-name{
						color: @light-color;
					}
				}

				&:after{
					background-color: @light-color;
				}
			}
		}

		&:last-child{
			border-bottom: 0;
		}
	}
}

//Phone resolution
@media @phone{

	.be-notifications{

		.navbar-nav .open &{
			position: absolute;
			background-color: @light-color;
		}
	}
}

@media @small-phone{

	.be-notifications{

		.navbar-nav .open &{
			position: fixed;
		}
	}
}