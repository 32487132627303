/* Effect 11: Super scaled */
.@{modal-prefix}-effect-11 .@{modal-prefix}-content {
	-webkit-transform: scale(2);
	-moz-transform: scale(2);
	-ms-transform: scale(2);
	transform: scale(2);
	opacity: 0;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.@{modal-prefix}-show.@{modal-prefix}-effect-11 .@{modal-prefix}-content {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 1;
}