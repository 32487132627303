/* Effect 8: 3D flip horizontal */
.@{modal-prefix}-effect-8.@{modal-prefix}-container {
	-webkit-perspective: 1300px;
	-moz-perspective: 1300px;
	perspective: 1300px;
}

.@{modal-prefix}-effect-8 .@{modal-prefix}-content {
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: rotateY(-70deg);
	-moz-transform: rotateY(-70deg);
	-ms-transform: rotateY(-70deg);
	transform: rotateY(-70deg);
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	opacity: 0;
}

.@{modal-prefix}-show.@{modal-prefix}-effect-8 .@{modal-prefix}-content {
	-webkit-transform: rotateY(0deg);
	-moz-transform: rotateY(0deg);
	-ms-transform: rotateY(0deg);
	transform: rotateY(0deg);
	opacity: 1;
}
