/*------------------------------------------------------------------
  [Bootstrap grid]
*/

.row{

	@media @small-phone{
		margin-left: -10px;
		margin-right: -10px;
	}
}