/*------------------------------------------------------------------
  [User timeline widget]
*/
.user-timeline{
	padding: 0;
	margin: 5px 0 15px;
	list-style: none;
	position: relative;

	&:before{
		content: "";
		position: absolute;
		left: 7px;
		top: 2px;
		height: 100%;
		width: 2px;
		background-color: @primary-color;
	}

	&:after{
		content: "";
		position: absolute;
		left: 4px;
		bottom: -2px;
		height: 8px;
		width: 8px;
		background-color: @primary-color;
		border-radius: 50%;
	}

	> li{
		display: block;
		position: relative;
		padding-left: 30px;
		padding-bottom: 27px;

		&:before{
			content: "";
			position: absolute;
			left: 0;
			top: 2px;
			height: 16px;
			width: 16px;
			background-color: @light-color;
			border-radius: 50%;
			border: 1px solid @primary-color;
		}

		&.latest{

			&:before{
				background-color: @primary-color;
			}
		}
	}

	&-date{
		float: right;
		color: lighten(@text-color, 20%);
		margin-bottom: 0;
	}

	&-description{
		color: lighten(@text-color, 40%);
		margin-top: 2px;
		font-size: 11px;
	}

	&-title{
		font-size: 13px;
		font-weight: @font-weight-medium;
	}

	&-compact{

		.user-timeline-date{
			float: right;

			margin-bottom: 0;
			line-height: 22px;
			font-weight: @font-weight-bold;
		}

		.user-timeline-title{
			font-size: 13px;
			font-weight: 300;
		}

		.user-timeline-description{
			color: lighten(@text-color, 20%);
			margin-top: 2px;
			font-size: 11px;
		}

		&:before{
			background-color: @warning-color-text;
		}

		&:after{
			background-color: @warning-color-text;
		}

		> li{
			padding-bottom: 10px;

			&:last-child{
				padding-bottom: 25px;
			}

			&:before{
				border: 1px solid @warning-color-text;
			}

			&.latest{

				&:before{
					background-color: @warning-color-text;
				}
			}
		}
	}
}



.notes-timeline{
	padding: 0;
	margin: 5px 0 15px;
	list-style: none;
	position: relative;

	&:before{
		content: "";
		position: absolute;
		left: 7px;
		top: 2px;
		height: 100%;
		width: 2px;
		background-color: @gray;
	}

	&:after{
		content: "";
		position: absolute;
		left: 4px;
		bottom: -2px;
		height: 8px;
		width: 8px;
		background-color: @gray;
		border-radius: 50%;
	}

	> li{
		display: block;
		position: relative;
		padding-left: 30px;
		padding-bottom: 15px;

		&:before{
			content: "";
			position: absolute;
			left: 0;
			top: 2px;
			height: 16px;
			width: 16px;
			background-color: @gray-light;
			border-radius: 50%;
			border: 1px solid @gray;
		}

		&.primary{
			&:before{
				background-color: @primary-color;
			}
		}

		&.success{
			&:before{
				background-color: @success-color;
			}
		}

		&.warning{
			&:before{
				background-color: @warning-color;
			}
		}

		&.danger{
			&:before{
				background-color: @danger-color;
			}
		}

		button{
			position: absolute;
			top: 1px;
			right: 5px;
		}
	}

	&-description{
		padding-top: 2px;
		padding-right: 20px;
		padding-bottom: 2px;
		font-size: 13px;

		&:hover{
			background: #f5f5f5;
		}
	}

	&-author{
		font-size: 13px;
		color: lighten(@text-color, 40%);
		font-weight: @font-weight-medium;
	}

}