/* Effect 3: Slide from the bottom */
.@{modal-prefix}-effect-3 .@{modal-prefix}-content {
	-webkit-transform: translateY(20%);
	-moz-transform: translateY(20%);
	-ms-transform: translateY(20%);
	transform: translateY(20%);
	opacity: 0;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.@{modal-prefix}-show.@{modal-prefix}-effect-3 .@{modal-prefix}-content {
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
}