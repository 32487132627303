/*------------------------------------------------------------------
  [Badges]
*/
.badge{
	font-size: @font-size-small - 1px;
	font-weight: @font-weight-bold;
	color: @badge-color;
	border: 1px solid darken(@light-color, 15%);
}

.badge-primary{
 .badge-color(@primary-color-text); 
}

.badge-success{
 .badge-color(@success-color-text); 
}

.badge-info{
 .badge-color(@info-color-text); 
}

.badge-warning{
 .badge-color(@warning-color-text); 
}

.badge-danger{
 .badge-color(@danger-color-text); 
}
