/* the norm */
#gritter-notice-wrapper {
	position:fixed;
	top:20px;
	right:20px;
	width:301px;
	z-index:9999;
}
#gritter-notice-wrapper.top-left {
    left: 20px;
    right: auto;
}
#gritter-notice-wrapper.bottom-right {
    top: auto;
    left: auto;
    bottom: 20px;
    right: 20px;
}
#gritter-notice-wrapper.bottom-left {
    top: auto;
    right: auto;
    bottom: 20px;
    left: 20px;
}
.gritter-item-wrapper {
	position:relative;
	margin:0 0 10px 0;
	background:url('@{img-path}/ie-spacer.gif'); /* ie7/8 fix */ 
}
.gritter-top {
	background:url(@{img-path}/gritter.png) no-repeat left -30px;
	height:10px;
}
.hover .gritter-top {
	background-position:right -30px;
}
.gritter-bottom {
	background:url(@{img-path}/gritter.png) no-repeat left bottom;
	height:8px;
	margin:0;
}
.hover .gritter-bottom {
	background-position: bottom right;
}
.gritter-item {
	display:block;
	background:url(@{img-path}/gritter.png) no-repeat left -40px;
	color:#eee;
	padding:2px 11px 8px 11px;
	font-size: 11px;
	font-family:verdana;
}
.hover .gritter-item {
	background-position:right -40px;
}
.gritter-item p {
	padding:0;
	margin:0;
	word-wrap:break-word;
}
.gritter-close {
	display:none;
	position:absolute;
	top:5px;
	left:3px;
	background:url(@{img-path}/gritter.png) no-repeat left top;
	cursor:pointer;
	width:30px;
	height:30px;
	text-indent:-9999em;
}
.gritter-title {
	font-size:14px;
	font-weight:bold;
	padding:0 0 7px 0;
	display:block;
	text-shadow:1px 1px 0 #000; /* Not supported by IE :( */
}
.gritter-image {
	width:48px;
	height:48px;
	float:left;
}
.gritter-with-image,
.gritter-without-image {
	padding:0;
}
.gritter-with-image {
	width:220px;
	float:right;
}
/* for the light (white) version of the gritter notice */
.gritter-light .gritter-item,
.gritter-light .gritter-bottom,
.gritter-light .gritter-top,
.gritter-light .gritter-close {
    background-image: url(@{img-path}/gritter-light.png);
    color: #222;
}
.gritter-light .gritter-title {
    text-shadow: none;
}




/*------------------------------------------------------------------
  [Notifications]
*/
#gritter-notice-wrapper{
	width: @notification-width;
	top: @notification-top-position;
}

//Social Color Mixin
.social-gritter(@background){
	background: @background;

	.gritter-item{

		.gritter-img-container{
			background-color: darken(@background, 3%);
		}
	}
}

.gritter-item-wrapper{
	background: url("@{img-path}/gritter-bg.png");
	box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);

	.gritter-item{
		font-family:'Open Sans',sans-serif;
		background: transparent;
		color: @light-color;
		padding: 0;
		display: table;
		table-layout: fixed;
		width: 100%;

		.gritter-img-container{
			display: table-cell;
			width: 26%;
			vertical-align: middle;
			text-align: center;
			background-color: darken(@dark-color, 5%);

			.gritter-image{
				float: none;
			}
		}

		.gritter-content{
			width: auto;

			&.gritter-with-image, &.gritter-without-image{
				padding: 15px 35px 17px 15px;
				color: darken(@light-color, 15%);
				width: 267px;
				display: table-cell;

				.gritter-close{
					display: block;
					top: 0;
					right: 0;
					left: auto;
					height: 30px;
					width: 30px;
					font-size: 0;
					background: transparent;
					text-indent: inherit;
					opacity: 0.4;

					&:hover{
						opacity: 0.8;
					}

					&:after{
						content: '\f136';
						font-family: @icons-font;
						color: @light-color;
						font-size: 20px;
						text-shadow: none;
						line-height: 35px;
						margin-left: 7px;
					}
				}

				.gritter-title{
					text-shadow: none;
					font-weight:300;
					font-size:17px;
				}

				p{
					font-size:12px;
					line-height:19px;
				}
			}

			.gritter-without-image{
				border-left: 0;
			}
		}	
	}

	&.img-rounded{
		border-radius: 0;

		.gritter-img-container{
			
			.gritter-image{
				border-radius: 50%;
			}
		}
	}

	&.clean{
		background: @light-color;

		.gritter-item{
			color: @text-color;

			.gritter-img-container{
				background-color: darken(@light-color, 2%);
			}

			.gritter-content{
				color: @text-color;

				.gritter-close{
					opacity: 0.5;
					
					&:after{
						color: @text-color;
					}
				}
			}
		}
	}

	&.color{

		.gritter-item{
			.gritter-content{
				color: @light-color;

				.gritter-close{
					opacity: 0.7;
					
					&:hover{
						opacity: 1;
					}
				}
			}
		}
	}

	&.primary	{ background: @primary-color; }
	&.success	{ background: @success-color; }
	&.warning { background: @warning-color; }
	&.danger	{ background: @danger-color; }
	&.dark { background: @dark-color; }


	&.facebook 		{ .social-gritter(@facebook); 		}
	&.twitter			{ .social-gritter(@twitter);  		}
	&.google-plus	{ .social-gritter(@google-plus);  }
	&.dribbble		{ .social-gritter(@dribbble);			}
	&.flickr			{ .social-gritter(@flickr);				}
	&.linkedin		{ .social-gritter(@linkedin);			}
	&.youtube			{ .social-gritter(@youtube);			}
	&.pinterest		{ .social-gritter(@pinterest);		}
	&.github			{ .social-gritter(@github);				}
	&.tumblr			{ .social-gritter(@tumblr);				}
}