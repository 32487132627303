//Button group
.btn-group, .input-group-btn {

	> .btn {

		//Fix the next button border color when the next element is :focus
		&.btn-default:hover + .btn,
		&.btn-default.active + .btn {
			border-left-color: darken(@btn-default-border, 13%);
		}

		&.btn-primary {
			.colored-btn-group(@btn-primary-bg);
		}

		&.btn-success {
			.colored-btn-group(@btn-success-bg);
		}

		&.btn-warning {
			.colored-btn-group(@btn-warning-bg);
		}

		&.btn-danger {
			.colored-btn-group(@btn-danger-bg);
		}

		&:last-of-type:not(.btn-default){
			border-right-color: transparent;

			&:hover{
				border-right-color: transparent;
			}
		}

		& + .dropdown-toggle{
			padding-left: 7px;
			padding-right: 7px;
		}
	}

	&:first-child{
		border-right-color: transparent;
	}

	&:last-child{
		border-right-color: transparent;
	}

	&.open {

		> .btn {

			&.btn-primary,
			&.btn-success,
			&.btn-warning,
			&.btn-danger {

				&:hover{
					box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.1);
				}
			}

			& + .btn-primary,
			& + .btn-success,
			& + .btn-warning,
			& + .btn-danger {
				border-left-color: rgba(0, 0, 0, 0.15);
			}
		}
	}
}

//Button group justified
.btn-group-justified {

	> .btn {
		border-right-width: 0;

		&:last-child {
			border-right-width: 1px;
		}
	}
}

//Vertical Button groups Style
.btn-group-vertical {

	> .btn {

		&.btn-default {

			&:hover, 
			&:focus {
				box-shadow: none;
			}

			&:active,
			&.active {
				box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.06);
			}
		}

		&.btn-primary {
			.colored-vertical-btn-group(@btn-primary-bg);
		}

		&.btn-success {
			.colored-vertical-btn-group(@btn-success-bg);
		}

		&.btn-warning {
			.colored-vertical-btn-group(@btn-warning-bg);
		}

		&.btn-danger {
			.colored-vertical-btn-group(@btn-danger-bg);
		}
	}
}

//Input group
.input-group-btn {

	> .btn {

		& + .dropdown-toggle {
			padding-left: 9px;
			padding-right: 9px;
		}
	}
}