/* Effect 18:  Slide from right with perspective on container */
.@{modal-prefix}-show.@{modal-prefix}-effect-18 ~ .container {
	height: 100%;
	overflow: hidden;
}

.@{modal-prefix}-show.@{modal-prefix}-effect-18 ~ .@{modal-prefix}-overlay {
	background: rgba(143,27,15,0.8);
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}

.@{modal-prefix}-show.@{modal-prefix}-effect-18 ~ .container,
.@{modal-prefix}-show.@{modal-prefix}-effect-18 ~ .@{modal-prefix}-overlay {
	-webkit-transform-style: preserve-3d;
	-webkit-transform-origin: 0% 50%;
	-webkit-animation: rotateRightSideFirst 0.5s forwards ease-in;
	-moz-transform-style: preserve-3d;
	-moz-transform-origin: 0% 50%;
	-moz-animation: rotateRightSideFirst 0.5s forwards ease-in;
	transform-style: preserve-3d;
	transform-origin: 0% 50%;
	animation: rotateRightSideFirst 0.5s forwards ease-in;
}

@-webkit-keyframes rotateRightSideFirst {
	50% { -webkit-transform: translateZ(-50px) rotateY(5deg); -webkit-animation-timing-function: ease-out; }
	100% { -webkit-transform: translateZ(-200px); }
}

@-moz-keyframes rotateRightSideFirst {
	50% { -moz-transform: translateZ(-50px) rotateY(5deg); -moz-animation-timing-function: ease-out; }
	100% { -moz-transform: translateZ(-200px); }
}

@keyframes rotateRightSideFirst {
	50% { transform: translateZ(-50px) rotateY(5deg); animation-timing-function: ease-out; }
	100% { transform: translateZ(-200px); }
}

.@{modal-prefix}-effect-18 .@{modal-prefix}-content {
	-webkit-transform: translateX(200%);
	-moz-transform: translateX(200%);
	-ms-transform: translateX(200%);
	transform: translateX(200%);
	opacity: 0;
}

.@{modal-prefix}-show.@{modal-prefix}-effect-18 .@{modal-prefix}-content {
	-webkit-transform: translateX(0);
	-moz-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
	-webkit-transition: all 0.5s 0.1s;
	-moz-transition: all 0.5s 0.1s;
	transition: all 0.5s 0.1s;
}