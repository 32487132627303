/* Effect 13: 3D slit */
.@{modal-prefix}-effect-13.@{modal-prefix}-container {
	-webkit-perspective: 1300px;
	-moz-perspective: 1300px;
	perspective: 1300px;
}

.@{modal-prefix}-effect-13 .@{modal-prefix}-content {
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: translateZ(-3000px) rotateY(90deg);
	-moz-transform: translateZ(-3000px) rotateY(90deg);
	-ms-transform: translateZ(-3000px) rotateY(90deg);
	transform: translateZ(-3000px) rotateY(90deg);
	opacity: 0;
}

.@{modal-prefix}-show.@{modal-prefix}-effect-13 .@{modal-prefix}-content {
	-webkit-animation: slit .7s forwards ease-out;
	-moz-animation: slit .7s forwards ease-out;
	animation: slit .7s forwards ease-out;
}

@-webkit-keyframes slit {
	50% { -webkit-transform: translateZ(-250px) rotateY(89deg); opacity: .5; -webkit-animation-timing-function: ease-out;}
	100% { -webkit-transform: translateZ(0) rotateY(0deg); opacity: 1; }
}

@-moz-keyframes slit {
	50% { -moz-transform: translateZ(-250px) rotateY(89deg); opacity: .5; -moz-animation-timing-function: ease-out;}
	100% { -moz-transform: translateZ(0) rotateY(0deg); opacity: 1; }
}

@keyframes slit {
	50% { transform: translateZ(-250px) rotateY(89deg); opacity: 1; animation-timing-function: ease-in;}
	100% { transform: translateZ(0) rotateY(0deg); opacity: 1; }
}