/* Effect 17:  Slide in from bottom with perspective on container */

.@{modal-prefix}-show.@{modal-prefix}-effect-17 ~ .container {
	height: 100%;
	overflow: hidden;
	-webkit-transition: -webkit-transform 0.3s;
	-moz-transition: -moz-transform 0.3s;
	transition: transform 0.3s;
}	

.@{modal-prefix}-show.@{modal-prefix}-effect-17 ~ .container,
.@{modal-prefix}-show.@{modal-prefix}-effect-17 ~ .@{modal-prefix}-overlay  {
	-webkit-transform: rotateX(-2deg);
	-moz-transform: rotateX(-2deg);
	-ms-transform: rotateX(-2deg);
	transform: rotateX(-2deg);
	-webkit-transform-origin: 50% 0%;
	-moz-transform-origin: 50% 0%;
	transform-origin: 50% 0%;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.@{modal-prefix}-effect-17 .@{modal-prefix}-content {
	opacity: 0;
	-webkit-transform: translateY(200%);
	-moz-transform: translateY(200%);
	-ms-transform: translateY(200%);
	transform: translateY(200%);
}

.@{modal-prefix}-show.@{modal-prefix}-effect-17 .@{modal-prefix}-content {
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
	-webkit-transition: all 0.3s 0.2s;
	-moz-transition: all 0.3s 0.2s;
	transition: all 0.3s 0.2s;
}