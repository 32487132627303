//Full colored modal
.modal-full-color(@color){

  .modal-header{
    background-color: @color;
  }

  .modal-body, .modal-footer{
    background-color: @color;
  }

  .btn-default{
		color: @color;

    &:active{
      color: @color;
    }
  }
}

//Colored header modal
.colored-header(@color){
  .modal-content{
    .modal-header{
      background-color: @color;
    }
  }
}