/*------------------------------------------------------------------
[General elements style]
*/

html{
	height:100%;
	overflow-x: hidden;
}

body {
	padding: 0;
	background-color: @body-background;
	height: 100%;
}

a{

	&:hover, &:focus{
		text-decoration: none;
		outline: none;
	}	
}

p{
	font-size: @paragraph-font-size;
	line-height: @paragraph-line-height;
}

//Fix for plugins like charts
canvas{
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}