//Tab Todo
.tab-todo{
  
  .todo-container{
    height: 100%;
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .todo-wrapper{
    display: table-row;
    height: 100%;
  }

  .be-scroller{
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  .todo-content{
    padding: 10px 20px 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .category-title{
    display: block;
    font-size: 15px;
    font-weight: @font-weight-regular;
    color: lighten(@text-color, 25%);
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid darken(@right-sidebar-background, 6%);
    margin-bottom: 15px;
  }
}

//Todo list
.todo-list{
  list-style: none;
  padding-left: 0;
  margin-bottom: 15px;

  > li{
    margin: 0;
    padding: 0;

    .be-checkbox{
      margin-right: 4px;

      input[type="checkbox"]{
       
        & + label{
          display: block;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:before{
            margin-right: 8px;
          }
        }

        &:checked + label{
          text-decoration: line-through;
          color: lighten(@text-color, 35%);
        }
      }

      .delete{
        display: none;
        float: right;
        font-size: 17px;
        color: lighten(@text-color, 45%);
        line-height: 22px;
        margin-left: 5px;
        cursor: pointer;

        &:hover{
          color: lighten(@text-color, 25%);
        }
      }

      &:hover{

        .delete{
          display: block;
        }
      }
    }
  }
}